<template>
  <div>
    <v-snackbar
        top
        v-model="snackbar"
    >
      {{ msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text

            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card color="#f2f3f7">
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
        >
          <template v-slot:item.date="{item}">
            <span>{{ item.created_at }}</span>
          </template>
          <template v-slot:item.body="{item}">
            <span>{{ item.body.substr(1, 50) }}</span>
          </template>
          <template v-slot:item.delete="{item}">
            <v-btn elevation="0" fab small color="error" @click="deleteComment(item.id)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.show="{item}">
            <v-btn elevation="0" fab small color="success" @click="showComment(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-if="selectedNote" v-model="showSelectedNoteDialog" width="50%">
      <v-card>
        <v-card-actions>
          <v-icon color="error" @click="showSelectedNoteDialog = false">mdi-close</v-icon>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h4>
                {{ selectedNote.title }}
              </h4>
            </v-col>
            <v-col cols="2">
            </v-col>
            <v-col cols="10" class="text-center">
              {{ selectedNote.body }}
            </v-col>
            <v-col cols="2">
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NotesIndex",
  data() {
    return {
      showSelectedNoteDialog: false,
      selectedNote: null,
      msg: '',
      snackbar: false,
      headers: [
        {
          width: 200,
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {text: 'ID', value: 'id'},
        {text: 'Title', value: 'title'},
        {text: 'Content', value: 'body'},
        {text: 'Delete', value: 'delete'},
        {text: 'Show', value: 'show'},
      ],
      items: []
    }
  },
  mounted() {
    this.getComments()
  },
  methods: {
    showComment(note) {
      this.selectedNote = note
      this.showSelectedNoteDialog = true
    },
    getComments() {
      window.axios.get('api/comment-index').then((res) => {
        this.items = res.data.data
      }).catch(() => {
      })
    },
    deleteComment(id) {
      window.axios.get('api/exam/comment/delete/' + id).then(() => {
        this.snackbar = true
        this.msg = 'Your note deleted successfully'
        this.getComments()
      }).catch((err) => {
        this.snackbar = true
        this.msg = err.messages
        this.getComments()
      })
    }
  }

}
</script>

<style scoped>

</style>